<template>
  <BaseLineChart
    :chart-data="chartData"
    :chart-options="options"
    :styles="styles"
  />
</template>

<script>
import 'chart.js/auto'
import { Line as BaseLineChart } from 'vue-chartjs/legacy'

export default {
  name: 'LineChart',
  components: {
    BaseLineChart
  },
  props: ['chartData', 'styles'],
  data() {
      return {
        options: {
            borderRadius: 10,

            maintainAspectRatio: false,
            responsive: true,
            
            plugins: {
              legend: {
                  display: true
              },
            },
            elements: {
              line: {
                  tension: 0.3,
              },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Age of Claim'
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Number of Claims'
                    }
                }
            },
        }
      }
  },
}
</script>