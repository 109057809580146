<template>
  <div>
  <b-row v-for="(worker, index) in workers" :key="index">
    <b-col cols="4">
      <b-card class="mb-1 shadow" style="min-width:200px;">
          <div class="d-flex align-items-center">
              <b-avatar size="sm" />
              <div style="color:#38455D;margin-left:8px;">{{worker.name}}</div>
          </div>
      </b-card>
    </b-col>
    <b-col>
        <worker-age-range-assignments :worker-id="worker.worker_id"/>
    </b-col>
  </b-row>
  </div>
</template>

<script>

import WorkerAgeRangeAssignments from './WorkerAgeRangeAssignments.vue'

export default {
  name: "WorkerListAgeRangeAssignments",
  components: { WorkerAgeRangeAssignments },
  props: {
    workers: {
      type: Array,
      required: true,
    },
  },
}
</script>