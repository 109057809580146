<template>
<div>
    <div class="d-flex mb-4" style="">
        <div class="avo-page-header-text" style="margin-right:30px">
            Worker's Performance
        </div>
    </div>
    <b-row>
        <b-col cols="8" class="">
            <b-row class="white-card shadow p-3 py-4 m-0 mb-4">
                <b-col cols="3" class="pl-2 pr-0">
                    <div class="d-flex flex-column justify-content-between pl-2" style="border-left:4px solid #5A31C1;">
                        <div class="avo-text-normal-grey pb-1">Average time to close</div>
                        <div class="d-flex align-items-center stat-value-text">{{stats.avgTimeToClose}} <div class="stat-subtext ml-2 mb-1">days</div></div>
                    </div>
                </b-col>
                <b-col cols="3" class="pl-0 pr-0">
                    <div class="d-flex flex-column justify-content-between pl-2" style="border-left: 4px solid #15ACC1;">
                    <div class="avo-text-normal-grey pb-1">Average claims worked</div>
                    <div class="d-flex align-items-center stat-value-text">{{stats.avgClaimsWorked}} <div class="stat-subtext ml-2 mb-1">per day</div></div>
                </div>
                </b-col>
                <b-col cols="3" class="pl-0 pr-0 ">
                    <div class="d-flex flex-column justify-content-between pl-2" style="border-left:4px solid #EEC05A;">
                    <div class="avo-text-normal-grey pb-1">Average tasks completed</div>
                    <div class="d-flex align-items-center stat-value-text">{{stats.avgTasksCompleted}} <div class="stat-subtext ml-2 mb-1">per day</div></div>
                </div>
                </b-col>
                <b-col cols="3" class="pl-2 pr-0">
                    <div class="d-flex flex-column justify-content-between pl-2" style="border-left:4px solid #FD4739;">
                    <div class="avo-text-normal-grey pb-1">Average issues</div>
                    <div class="d-flex align-items-center stat-value-text">{{stats.avgIssuesPerClaim}} <div class="stat-subtext ml-2 mb-1">per claim</div></div>
                </div>
                </b-col>
            </b-row>
            <b-row>
              <b-col>
                  <b-card class='white-card shadow mb-4'>
                    <div>
                      <b-row class="mb-4">
                          <b-col class="summary-card-header-txt">
                              Age Range Assignments
                          </b-col>
                          <b-col>
                            <b-button v-b-toggle.collapse-age-range-assignments class="float-right" variant="link" style="padding:0px">
                              <i v-if="!ageRangeAssignmentsVisible" class="fas fa-chevron-down"></i>
                              <i v-else class="fas fa-chevron-up"></i>
                            </b-button>
                          </b-col>
                      </b-row>
                      <b-collapse v-model="ageRangeAssignmentsVisible" id="collapse-age-range-assignments">
                        <worker-list-age-range-assignments v-if="ageRangeAssignmentsVisible" :workers="selectedWorkersBucket"/>
                      </b-collapse>
                    </div>
                  </b-card>
              </b-col>
            </b-row>
            <b-card class='white-card shadow mb-4'>
                <div class="summary-card-header-txt">
                    Claims
                </div>
                <donut-with-bar
                    :chart-data="claimsChartData"
                    :data-loading="claimsChartLoading"
                />
            </b-card>
            <b-card class='white-card shadow mb-4'>
                <div class="summary-card-header-txt">
                    Claims by Age
                </div>
                <div class="chart-container" style="height:350px">
                    <div v-if="claimsByAgeChartLoading" class="spinner-container">
                        <b-spinner></b-spinner>
                    </div>
                    <line-chart :chartData="claimsByAgeChartData" :styles="{width:'100%', height:'100%', position:'relative'}"></line-chart>
                </div>
            </b-card>
            <b-card class='white-card shadow mb-4 pr-2'>
                <div class="summary-card-header-txt">
                    Average Time to Close by Worker
                </div>
                <div class="chart-container" style="height:300px">
                    <div v-if="timeToCloseChartLoading" class="spinner-container">
                        <b-spinner></b-spinner>
                    </div>
                    <horizontal-bar-chart :chartData="timeToCloseChartData" :styles="{width:'100%', height:'100%', position:'relative'}"></horizontal-bar-chart>
                </div>
            </b-card>
            <b-card class='white-card shadow mb-4 pr-2'>
                <div class="summary-card-header-txt">
                    Claims Worked
                </div>
                <div class="chart-container" style="height:300px">
                    <div v-if="claimsWorkedChartLoading" class="spinner-container">
                        <b-spinner></b-spinner>
                    </div>
                    <horizontal-bar-chart :chartData="claimsWorkedChartData" :styles="{width:'100%', height:'100%', position:'relative'}"></horizontal-bar-chart>
                </div>
            </b-card>
            <b-card class='white-card shadow mb-4'>
                <div class="summary-card-header-txt">
                    Top Claim Issues
                </div>
                <donut-with-bar 
                    :chart-data="issueChartData" 
                    :data-loading="issueChartLoading"
                />
            </b-card>
        </b-col>
        <b-col cols="4" class="pl-0 pl-xl-2">
            <div class="worker-search-box shadow">
                <b-input-group class="search-group mb-4">
                    <div class="mb-2" style="font-size:16px;font-weight:300;color:white;">By Practice</div>
                    <multiselect
                        v-model="selectedPractice"
                        :options="practices"
                        track-by="practice_id" 
                        label="name"
                        :show-labels="false"
                        placeholder="Select a practice">
                    </multiselect>
                    
                </b-input-group>

                <b-input-group class="search-group mb-4">
                    <div class="mb-2" style="font-size:16px;font-weight:300;color:white;">By Worker Name</div>
                    <multiselect
                        v-model="selectedWorkersBucket"
                        :options="claimWorkers"
                        track-by="worker_id" 
                        label="name"
                        :multiple="true"
                        :show-labels="false"
                        placeholder="Select or de-select workers">
                    </multiselect>
                    
                </b-input-group>
                <div class="mb-3" style="font-size:20px;font-weight:300;color:white;">Worker's Assigned Claims</div>
                <div class="d-flex flex-column justify-content-start align-items-center">
                    <div v-for="(worker, index) in selectedWorkersBucket" :key="index" class="worker-card cursor-pointer" @click="selectWorker(index)">
                        <div class="d-flex align-items-center">
                            <b-avatar></b-avatar>
                            <div style="color:#38455D;margin-left:8px;">{{worker.name}}</div>
                        </div>
                        <div class="d-flex flex-column align-items-end justify-content-center">
                            <div class="stat-value-text">{{worker.assignments}}</div>
                            <div style="font-size:14px;color:#929BAA">Assigned Claims</div>
                        </div>
                    </div>
                </div>
            </div>
            <b-card class='white-card shadow mb-4'>
                <div class="d-flex flex-row justify-content-start pb-4">
                    <div class="summary-card-header-txt">
                        Issues by Worker
                    </div>
                </div>
                <b-row class="mb-3">
                    <b-col cols="4">
                        <div class="small-head-txt-med">Worker</div>
                    </b-col>
                    <b-col cols="3" class="text-right">
                        <div class="small-head-txt-med">Rejected</div>
                    </b-col>
                    <b-col cols="5">
                        <div class="small-head-txt-med">Top Reason</div>
                    </b-col>
                </b-row>
                <b-row v-for="item in workerIssuesInfo" :key="item.workerID" class="mb-3">
                    <b-col class="small-head-txt" cols="4">{{item.workerName}}</b-col>
                    <b-col style="color:var(--blue);font-weight:500;text-align:right;" cols="3">{{item.rejectedPercent}}%</b-col>
                    <b-col v-if="item.topReason" class="small-head-txt" cols="5">{{item.topReason}}({{item.topReasonPercent}}%)</b-col>
                    <b-col v-else class="small-head-txt" cols="5">N/A</b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</div> 
</template>

<script>
import {
  make_url,
  authenticated_request,
  formatDecimalShort as formatDecimal,
} from "../../common.js";
import Multiselect from "vue-multiselect";
import DonutWithBar from "../charts/DonutWithBar.vue";
import HorizontalBarChart from "../charts/HorizontalBarChart.vue";
import LineChart from "../charts/LineChart.vue";
import WorkerListAgeRangeAssignments from "./WorkerListAgeRangeAssignments.vue";

export default {
  name: "WorkerDash",
  components: {
    DonutWithBar,
    HorizontalBarChart,
    LineChart,
    Multiselect,
    WorkerListAgeRangeAssignments
},
  data() {
    return {
      search: {
        terms: "",
      },

      // for the claim stats header row
      stats: {
        avgTimeToClose: "--",
        avgIssuesPerClaim: 0,
        avgClaimsWorked: 0,
        avgTasksCompleted: 0,
      },

      // for the worker search/list box
      claimWorkers: [],
      selectedWorkersBucket: [],
      practices: [],
      selectedPractice: null,

      // for the worker issues table
      workerIssuesInfo: [],

      // for the top claims breakdown donut + progress bar chart
      claimsChartLoading: false,
      claimsChartData: {
        labels: ["Untouched", "Assigned", "Unresolved"],
        datasets: [
          {
            backgroundColor: ["#7A9CE5", "#41B0A5", "#EEC05A"],
            data: [],
          },
        ],
      },

      // top claim issues donut chart
      issueChartLoading: true,
      issueChartData: {
        hoverBorderWidth: 10,
        labels: [],
        datasets: [
          {
            label: "Data One",
            backgroundColor: [
              "#7A9CE5",
              "#41B0A5",
              "#D191DF",
              "#EEC05A",
              "#FEA487",
            ],
            data: [],
          },
        ],
      },

      // claims by age line chart
      claimsByAgeChartLoading: true,
      claimsByAgeChartData: {
        labels: [
          "0-30 Days",
          "31-60 Days",
          "61-90 Days",
          "91-120 Days",
          "120+ Days",
        ],
        datasets: [],
      },

      // average time to close horizontal bar chart
      timeToCloseChartLoading: true,
      timeToCloseChartData: {},

      // worked claims per worker horizontal bar chart
      claimsWorkedChartLoading: true,
      claimsWorkedChartData: {},
      
      ageRangeAssignmentsVisible: false,
    };
  },
  methods: {
    getStats() {
      const url = make_url("/reports/claims/workers/stats");
      const vm = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
        params: {
          practice_ids: this.selectedPractice?.practice_id || null,
        },
      })
        .then(function (response) {
          if (response) {
            console.log("Got a response from the server: ", response);
            const stats = response?.data;
            vm.stats.avgTimeToClose = formatDecimal(
              stats?.average_time_to_close_claim
            );
            vm.stats.avgIssuesPerClaim = formatDecimal(
              stats?.average_issues_per_claim || 0
            );
            vm.stats.avgClaimsWorked = formatDecimal(
              stats?.total_claims_worked / 30 || 0
            );
            vm.stats.avgTasksCompleted = formatDecimal(
              stats?.total_tasks_completed / 30 || 0
            );
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
      return promise;
    },
    getClaimWorkers() {
      const url = make_url("/reports/claims/workers");
      const vm = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
      })
        .then(function (response) {
          if (response) {
            vm.claimWorkers = response.data.workers;
            const slicedArray = response.data.workers.slice(0, 4);
            vm.selectedWorkersBucket = slicedArray;
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
      return promise;
    },
    getPractices() {
      const url = make_url("/practices");
      const vm = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
      })
        .then(function (response) {
          if (response) {
            vm.selectedPractice = { name: "All Practices", practice_id: null };
            vm.practices = response.data.practices;
            vm.practices.unshift(vm.selectedPractice);
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
      return promise;
    },
    getClaimWorkerAssignments() {
      const url = make_url("/reports/claims/workers/assignments");
      const vm = this;
      vm.claimsChartLoading = true;
      const promise = authenticated_request({
        method: "get",
        url: url,
        params: {
          practice_ids: this.selectedPractice?.practice_id || null,
        },
      })
        .then(function (response) {
          if (response) {
            const items = response.data;
            vm.claimsChartData = {
              labels: ["Untouched", "Assigned", "Worked"],
              datasets: [
                {
                  backgroundColor: ["#7A9CE5", "#41B0A5", "#EEC05A"],
                  data: [
                    items.unassigned_claim_count,
                    items.assigned_claim_count,
                    items.worked_claim_count,
                  ],
                },
              ],
            };
          }
        })
        .catch(function (error) {
          console.log(error.response);
        })
        .finally(function () {
          vm.claimsChartLoading = false;
        });
      return promise;
    },
    getClaimWorkerIssues(worker_ids) {
      const url = make_url("/reports/claims/workers/issues");
      const vm = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
        params: {
          worker_ids: worker_ids,
          practice_ids: this.selectedPractice?.practice_id || null,
        },
      })
        .then(function (response) {
          if (response) {
            const items = response.data.workers;
            var tempArr = [];
            for (const item of items) {
              var formatted = {
                workerID: item.worker_id,
                workerName: item.name,
                rejectedPercent:
                  item.claims_count == 0
                    ? 0
                    : (item.flag_count / item.claims_count).toFixed(0),
                topReason: item.most_common_issue_type,
                topReasonPercent:
                  item.flag_count == 0
                    ? 0
                    : (item.most_common_issue_count / item.flag_count).toFixed(
                        0
                      ),
              };
              tempArr.push(formatted);
            }
            vm.workerIssuesInfo = tempArr;
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
      return promise;
    },
    getWorkerClaimsByAge(worker_ids) {
      const url = make_url("/reports/claims/workers/age");
      const vm = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
        params: {
          worker_ids: worker_ids,
          practice_ids: this.selectedPractice?.practice_id || null,
        },
      })
        .then(function (response) {
          if (response) {
            const workers = response.data.workers;

            var lineColors = [
              "#37D5D9",
              "#EEC05A",
              "#7550CB",
              "#FE816B",
              "#67B765",
              "#FE679F",
            ];
            var tempArr = [];
            workers.forEach(function (worker, index) {
              tempArr.push({
                label: worker.name,
                data: [
                  worker.claim_count_0_30_days,
                  worker.claim_count_31_60_days,
                  worker.claim_count_61_90_days,
                  worker.claim_count_91_120_days,
                  worker.claim_count_121_days,
                ],
                backgroundColor: "transparent",
                // cycles through line color array
                borderColor: lineColors[index % lineColors.length],
              });
            });
            vm.claimsByAgeChartData = {
              labels: [
                "0-30 Days",
                "31-60 Days",
                "61-90 Days",
                "91-120 Days",
                "120+ Days",
              ],
              datasets: tempArr,
            };
            vm.claimsByAgeChartLoading = false;
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
      return promise;
    },
    getWorkerTimeToClose(worker_ids) {
      const url = make_url("/reports/claims/workers/time");
      const vm = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
        params: {
          worker_ids: worker_ids,
          practice_ids: this.selectedPractice?.practice_id || null,
        },
      })
        .then(function (response) {
          if (response) {
            const items = response.data;

            var countArr = [],
              labelArr = [];
            items.workers.forEach(function (worker) {
              // Only show workers who have a > 0 worked claim count?
              // if(worker.average_time_to_close_claim){
              labelArr.push(worker.name);
              countArr.push(worker.average_time_to_close_claim);
              // }
            });
            vm.timeToCloseChartData = {
              labels: labelArr,
              datasets: [
                {
                  backgroundColor: "#7A9CE5",
                  data: countArr,
                },
              ],
            };
            vm.timeToCloseChartLoading = false;
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
      return promise;
    },
    getWorkerClaimsWorked(worker_ids) {
      const url = make_url("/reports/claims/workers/worked");
      const vm = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
        params: {
          worker_ids: worker_ids,
          practice_ids: this.selectedPractice?.practice_id || null,
        },
      })
        .then(function (response) {
          if (response) {
            const items = response.data;

            var countArr = [],
              labelArr = [];
            items.workers.forEach(function (worker) {
              // Only show workers who have a > 0 worked claim count?
              // if(worker.worked_claim_count){
              labelArr.push(worker.name);
              countArr.push(worker.worked_claim_count);
              // }
            });
            vm.claimsWorkedChartData = {
              labels: labelArr,
              datasets: [
                {
                  backgroundColor: "#FEA487",
                  data: countArr,
                },
              ],
            };
            vm.claimsWorkedChartLoading = false;
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
      return promise;
    },
    getTopClaimIssues() {
      const url = make_url("/reports/claims/issues");
      const vm = this;

      const promise = authenticated_request({
        method: "get",
        url: url,
        params: {
          practice_ids: this.selectedPractice?.practice_id || null,
        },
      })
        .then(function (response) {
          if (response) {
            const issues = response.data;
            vm.issueChartData = {
              hoverBorderWidth: 10,
              labels: Object.keys(issues),
              datasets: [
                {
                  label: "Data One",
                  backgroundColor: [
                    "#7A9CE5",
                    "#41B0A5",
                    "#D191DF",
                    "#EEC05A",
                    "#FEA487",
                  ],
                  data: Object.values(issues),
                },
              ],
            };
            vm.issueChartLoading = false;
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
      return promise;
    },
    selectWorker(index) {
      this.$store.commit(
        "workers/selectWorker",
        this.selectedWorkersBucket[index]
      );
    },
  },
  computed: {
    worker: function () {
      return this.$store.state.workers.selected_worker;
    },
    formattedSelectedWorkerIds: function () {
      var workerIDsArr = [];
      for (const worker of this.selectedWorkersBucket) {
        workerIDsArr.push(worker.worker_id);
      }

      return workerIDsArr.toString();
    },
  },
  watch: {
    formattedSelectedWorkerIds: function (newValues) {
      this.getWorkerClaimsByAge(newValues);
      this.getWorkerTimeToClose(newValues);
      this.getClaimWorkerIssues(newValues);
      this.getWorkerClaimsWorked(newValues);
    },
    selectedPractice: function () {
      this.issueChartLoading = true;
      (this.claimsByAgeChartLoading = true),
        (this.timeToCloseChartLoading = true),
        (this.claimsWorkedChartLoading = true);
      var oldWorkerIds = this.formattedSelectedWorkerIds;
      this.getStats();
      this.getClaimWorkerAssignments();
      this.getTopClaimIssues();
      const url = make_url("/reports/claims/workers");
      const vm = this;
      authenticated_request({
        method: "get",
        url: url,
        params: {
          assigned_practice: this.selectedPractice?.practice_id || null,
        },
      })
        .then(function (response) {
          if (response) {
            vm.selectedWorkersBucket = response.data.workers;
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
      // if watch did not trigger
      if (oldWorkerIds == this.formattedSelectedWorkerIds) {
        this.getWorkerClaimsByAge(oldWorkerIds);
        this.getWorkerTimeToClose(oldWorkerIds);
        this.getClaimWorkerIssues(oldWorkerIds);
        this.getWorkerClaimsWorked(oldWorkerIds);
      }
    },
  },
  async mounted() {
    this.getClaimWorkers();
    this.getPractices();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* increasing b-containers max-width for larger screens */
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
}
.white-card {
  border-radius: 5px 5px 5px 5px;
  background: white;
  border: 1px solid #e2e4eb;
}
.stat-value-text {
  font-size: 28px;
  color: #010c14;
  font-weight: 500;
  line-height: 1;
}
.stat-subtext {
  font-weight: 400;
  font-size: 20px;
  color: #929baa;
  line-height: 0;
}
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 200px;
  overflow: visible !important;
}
.spinner-container {
  position: absolute;
  top: 50%;
  left: 45%;
}

/* styles for custom Claims progress bar-type graph */
.thin-bar-background {
  background-color: #e7eaee;
  height: 7px;
  border-radius: 4px;
}
.thin-bar-overlay {
  height: 7px;
  border-radius: 4px;
  margin-top: -7px;
}
.absolute-centering {
  position: absolute;
  /* move the element half way across the screen */
  left: 50%;
  /* allow the width to be calculated dynamically */
  width: auto;
  /* then move the element back again using a transform */
  transform: translateX(-50%);
}
.small-head-txt {
  font-size: 14px;
  color: #38455d;
}
.small-head-txt-med {
  font-weight: 500;
  font-size: 14px;
  color: #38455d;
}
.small-head-txt-bold {
  font-weight: 600;
  font-size: 14px;
  color: #38455d;
}
/* worker search */
.worker-search-box {
  background-color: var(--blue);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 24px;
}
.worker-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;
  width: 100%;
  min-height: 90px;
  background-color: white;
  border-radius: 5px;
}
::v-deep .search-group {
  position: relative;
  border: none;
  border-radius: 5px;
  max-width: 650px;
}
::v-deep .worker-search-input,
.input-group-text {
  height: 50px;
  border: none;
  border-radius: 5px;
  background: white;
  color: #9ca3ad;
}
::v-deep .worker-search-input::placeholder {
  color: #9ca3ad;
  font-size: 12px;
}
</style>