<template>
    <div>
        <div v-if="workerSelected">
            <div>
                <back-button @click="clearWorker()">Back to Workers</back-button>
            </div>
            <worker-view />
        </div>
        <div v-else>
            <worker-dash />
        </div>
    </div>
</template>

<script>
import WorkerDash from "../components/workers/WorkerDash.vue"
import WorkerView from "../components/workers/WorkerView.vue"
import BackButton from '../components/BackButton.vue'

export default {
  name: 'Workers',
  components: {
      WorkerDash,
      WorkerView,
      BackButton,
  },
  data(){
      return {
          workers: [],
          search: {}
      }
  },
  methods: {
        clearWorker(){
            this.$store.commit("workers/clearSelectedWorker");
        },
  },
  computed: {
      workerSelected: function(){
          return (this.$store.state.workers.selected_worker != null);
      }
  }
}
</script>